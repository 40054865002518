/* Custom page CSS
-------------------------------------------------- */

/* Not required for template or sticky footer method. */

main>.container {
  padding: 30px 15px 0;
}

.footer {
  background-color: #f5f5f5;
}

.footer>.container {
  padding-right: 15px;
  padding-left: 15px;
  /* text-align: center; */
}

code {
  font-size: 80%;
}

.logo {
  margin-right: 5px;
  vertical-align: top;
}

footer .list-inline>li>a::after {
  content: "|";
  padding-left: 10px;
}

footer .list-inline>li:last-child>a::after {
  content: none;
}

pre {
  overflow: visible;
}

.lang-list .list-inline>li>a::after {
  content: "|";
  padding-left: 10px;
}

.lang-list .list-inline>li:last-child>a::after {
  content: none;
}

.counts-info {
  font-size: small;
}

.box-main .google-auto-placed {
  display: none;
}

.timer-main .google-auto-placed {
  display: none;
}

.timer {
  font-size: 145px;
  font-family: monospace;
  color: #007bff;
  background-color: black;
}

.timer input[type="number"] {
  width: 180px;
  background-color: inherit;
  color: inherit;
  border: none;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timer input[type="number"]:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.timer-ms {
  width: 265px !important;
}

.counter-main .google-auto-placed {
  display: none;
}

.counter {
  font-size: 145px;
  font-family: monospace;
  color: #007bff;
  background-color: black;
  line-height: 1;
  display: inline-table;
  padding-top: 40px;
  padding-bottom: 40px;
}

.counter-fullscreen {
  font-size: 400px;
}

.btn-timer {
  padding: 10px 50px 10px 50px;
  font-size: 30px;
}

.btn-timer span {
  display: block;
  font-size: 15px;
  font-style: italic;
}

.btn-counter {
  padding: 1px 30px 5px 30px;
  font-size: 30px;
  width: 285px;
}

.btn-counter span {
  display: block;
  font-size: 15px;
  font-style: italic;
}

.btn-shortcut {
  font-size: 25px;
  width: 115px
}

.uuid {
  text-align: center;
  font-size: xx-large;
  width: 65%;
  margin-right: 10px;
}

.uuid-container {
  place-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.qrcode {
  text-align: center;
  font-size: xx-large;
  width: 65%;
  margin-right: 10px;
}

.qrcode-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.qr-download {
  flex-direction: column;
  align-items: center;
}

.btn-wrap {
  white-space: normal !important;
}

.container.no-ad>.google-auto-placed {
  display: none !important;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.btn-clipboard {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 10;
  display: block;
  padding: .25rem .5rem;
  font-size: 75%;
  color: #818a91;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: .25rem;
}

.btn-clipboard:hover {
  color: #fff;
  background-color: #027de7;
}

.text-result {
  background-color: #f7f7f9;
  height: 293px;
  overflow: auto;
  width: 100%;
  margin-bottom: 7px;
}

.text-result pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.text-selected {
  background-color: #f7f7f9;
  height: 293px;
  overflow: auto;
  width: 100%;
  margin-bottom: 7px;
  text-align: center;
  padding-top: 15%;
  color: red;
  font-size: 60px;
}


.timer-pomodoro {
  font-size: 65px;
  font-family: monospace;
  color: #fff;
  background-image: url('./static/img/pomodoro.png');
  background-repeat: no-repeat;
  background-size: 455px;
  background-position: center;
  height: 435px;
}

.timer-pomodoro .display {
  margin-top: 75px;
}


@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: text-bottom;
  border: .25em solid;
  border-color: currentColor transparent currentColor currentColor;
  border-radius: 50%;
  animation-name: spinner-border;
  animation-duration: .75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-border-reverse {
  border-color: transparent currentColor transparent transparent;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

/* Growing circle */ 

@keyframes spinner-grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.spinner-grow {
  position: relative;
  display: inline-block;
  width: 8rem;
  height: 8rem;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  animation-name: spinner-grow;
  animation-duration: .75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.form-range {
  width: 100%;
  padding: 0;
  background-color: transparent;
}

.box-main .form-check {
  padding-left: unset;
}
.box-main .form-check label {
  margin-bottom: 0.5rem !important;
}
.box-main .form-check-input {
  margin-left: 5px;
}

.box-randomNumber .text-selected {
  height: 125px;
  margin-bottom: 0;
  padding-top: 8px; 
  white-space: nowrap;
  overflow-x: auto !important;
  overflow: unset;
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-size: 40px;
}

.rnd-number-result {
  padding: 0.75rem;
}


#pickerWheel {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

#wheel {
  display: block;
  width: 100%;
}

#spin {
  font: 1.5em/0 sans-serif;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 30%;
  margin: -15%;
  background: #fff;
  color: #fff;
  box-shadow: 0 0 0 8px currentColor, 0 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  transition: 0.8s;

}

#spin::after {
  content: "";
  position: absolute;
  top: -17px;
  border: 10px solid transparent;
  border-bottom-color: currentColor;
  border-top: none;
}

#spinSub {
  font: 0.7em/0 sans-serif;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 54%;
  left: 50%;
  width: 30%;
  height: 30%;
  margin: -15%;
  background: transparent;
  color: #fff;
  transition: 0.8s;

}

/* marquee start */

.marquee {
  /* width: 450px; */
  /* background-color: yellow;
  border: 1px dashed red;
  color: black; */
  padding: unset;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 10px;
}
.marquee p {
  margin-top: revert;
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
  /* text-transform: uppercase; */
  font-weight: bold;
}

.marquee .close {
  top: -3px;
  padding: 0;
}

@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}

/* marquee end */

.pwd .btn-primary {
  margin: 5px;
}


/* Landscape tablets and medium desktops */

@media (min-width: 992px) and (max-width: 1199px) {
  .timer {
    font-size: 110px;
  }
  .timer input[type="number"] {
    width: 140px;
  }
  .counter-fullscreen {
    font-size: 190px;
  }
  .timer-ms {
    width: 205px !important;
  }
  .uuid {
    font-size: x-large;
  }
  .card-uuid {
    min-width: 285px;
  }
}

/* Portrait tablets and small desktops */

@media (min-width: 768px) and (max-width: 991px) {
  .timer {
    font-size: 90px;
  }
  .timer input[type="number"] {
    width: 115px;
  }
  .timer-ms {
    width: 165px !important;
  }
  .counter-fullscreen {
    font-size: 190px;
  }
  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 25px;
  }
  .btn-timer span {
    font-size: 19px;
  }
  .btn-counter {
    padding: 5px 25px 5px 25px;
    font-size: 32px;
  }
  .btn-counter span {
    font-size: 16px;
  }
  .uuid {
    font-size: large;
  }
  .card-uuid {
    min-width: 205px;
  }
  .pwd.btn-group {
    display: flex;
    flex-direction: column;
  }
}

/* Landscape phones and portrait tablets */

@media (min-width: 575.99px) and (max-width: 767px) {
  .timer {
    font-size: 45px;
  }
  .timer input[type="number"] {
    width: 60px;
  }
  .timer-ms {
    width: 85px !important;
  }
  .counter {
    padding-top: 0;
    padding-bottom: 0;
  }
  .counter-main p {
    margin-bottom: 0;
  }
  .counter-fullscreen {
    font-size: 130px;
  }
  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 15px;
  }
  .btn-timer span {
    font-size: 9px;
  }
  .btn-counter {
    padding: 5px 25px 5px 25px;
    font-size: 25px;
  }
  .btn-counter span {
    font-size: 12px;
  }
  .uuid {
    font-size: larger;
    width: 100%;
    margin-bottom: 15px;
  }
  .uuid-container {
    flex-direction: column;
  }
  .card-uuid {
    min-width: 145px;
  }
  .timer-pomodoro {
    font-size: 50px;
    background-size: 330px;
    height: 320px;
  }
  .pwd.btn-group {
    display: flex;
    flex-direction: column;
  }
}

/* Portrait phones and smaller */

@media (max-width: 575.98px) {
  .timer {
    font-size: 40px;
  }
  .timer input[type="number"] {
    width: 50px;
  }
  .timer-ms {
    width: 75px !important;
  }
  .counter-fullscreen {
    font-size: 130px;
  }
  .btn-timer {
    padding: 10px 25px 10px 25px;
    font-size: 15px;
  }
  .btn-timer span {
    font-size: 9px;
  }
  .btn-counter {
    padding: 5px 25px 5px 25px;
    font-size: 25px;
  }
  .btn-counter span {
    font-size: 12px;
  }
  .uuid {
    font-size: small;
    width: 100%;
    margin-bottom: 15px;
  }
  .uuid-container {
    flex-direction: column;
  }
  .timer-pomodoro {
    font-size: 45px;
    background-size: 295px;
    height: 285px;
  }
  .timer-pomodoro+div>div>label {
    font-size: small;
  }
  .pwd.btn-group {
    display: flex;
    flex-direction: column;
  }
}